








import { Component, Vue } from 'vue-property-decorator';
import ThemeModule from '@/store/modules/theme';

/**
 * CMSPage component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSPage extends Vue {
    public get themeName() {
        return ThemeModule.themeName;
    }
}
