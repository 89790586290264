import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store/';
import Vuetify from '@/plugins/vuetify'

/**
 * VuexModule for theme management
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Module({ store: store, namespaced: true, name: 'theme', dynamic: true })
class ThemeModule extends VuexModule {
    // boolean that defines wheather darkmode is activated or not
    private _darkMode: boolean = localStorage.getItem('colorScheme') == 'dark' ? true : false;

    // Theme name
    private _themeName: string = localStorage.getItem('themeName') || 'default';

    /**
     * Assigns the given value to _darkMode
     * 
     * @param value boolean that represents whether the darkMode is active
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    private SET_DARKMODE(value: boolean) {
        this._darkMode = value;
    }

    /**
     * Assigns the given value to _themeName 
     * 
     * @param theme name of theme
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Mutation
    public SET_THEME(theme: string) {
        this._themeName = theme;
    }

    /** 
     * Toggles the darkMode
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public toggleDarkMode() {
        const colorScheme = !this._darkMode ? 'dark' : 'light';
        localStorage.setItem('colorScheme', colorScheme);
        this.SET_DARKMODE(!this._darkMode);
        this.loadColorScheme();
    }

    /** 
     * Sets the theme in Vuetify 
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    @Action
    public loadColorScheme() {
        Vuetify.framework.theme.dark = this._darkMode;
    }

    /**
     * @returns _themeName
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get themeName() {
        return this._themeName;
    }

    /**
     * @returns _darkMode
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    public get darkMode() {
        return this._darkMode;
    }
}

// Export ThemeModule
export default getModule(ThemeModule);